import { createAction, props } from '@ngrx/store';

export const deleteCustomersFromEmailRequest = createAction(
  '[Delete Customers From Email] Request',
  props<{ emails: string[] }>(),
);

export const deleteCustomersFromEmailSuccess = createAction(
  '[Delete Customers From Email] Success',
);

export const deleteCustomersFromEmailFailure = createAction(
  '[Delete Customers From Email] Failure',
  props<{ error: any }>(),
);

export const resetState = createAction(
  '[Delete Customers From Email] Reset State',
);
