import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { WelcomeAutomaticEmailsLogDetails } from '../../models';

export const featureAdapter: EntityAdapter<WelcomeAutomaticEmailsLogDetails> =
  createEntityAdapter<WelcomeAutomaticEmailsLogDetails>({
    selectId: (model) => model.id,
  });

export interface State extends EntityState<WelcomeAutomaticEmailsLogDetails> {
  isLoading?: boolean;
  error?: any;
}

export const initialState: State = featureAdapter.getInitialState({
  isLoading: false,
  error: null,
});
