import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { UserNotesStoreEffects } from './effects';
import { userNotesReducer } from './reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('userNotes', userNotesReducer),
    EffectsModule.forFeature([UserNotesStoreEffects]),
  ],
})
export class UserNotesStoreModule {}
