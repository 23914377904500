import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { PropertiesExpireDateStoreEffects } from './effects';
import { propertiesExpireDateReducer } from './reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('propertiesExpireDate', propertiesExpireDateReducer),
    EffectsModule.forFeature([PropertiesExpireDateStoreEffects]),
  ],
})
export class PropertiesExpireDateStoreModule {}
