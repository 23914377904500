import { createAction, props } from '@ngrx/store';

import { AdminStats, AdminStatsType } from '../../models';
import { SearchParams } from '../../models/objects/search-params';

export const loadRequest = createAction(
  '[Admin Stats] Load Request',
  props<{
    statsType: AdminStatsType;
    searchParams: SearchParams;
    onSuccess?: (data: AdminStats) => void;
  }>(),
);

export const loadSuccess = createAction(
  '[Admin Stats] Load Success',
  props<{ data: AdminStats; statsType: AdminStatsType }>(),
);

export const loadFailure = createAction(
  '[Admin Stats] Load Failure',
  props<{ error: any }>(),
);

export const reseState = createAction('[Admin Stats] Reset State');
