import { createAction, props } from '@ngrx/store';

import {
  PropertiesExpireDate,
  UpdatePropertiesDetailsRequest,
  UpdatePropertiesModulesDetailsRequest,
} from '../../models';
import { Hooks } from '@app/helpers';

export const loadLookupRequest = createAction(
  '[Properties Expire Date] Load Lookup Request',
  props<{ value: string }>(),
);

export const loadLookupSuccess = createAction(
  '[Properties Expire Date] Load Lookup Success',
  props<{
    items: PropertiesExpireDate[];
  }>(),
);

export const loadLookupFailure = createAction(
  '[Properties Expire Date] Load Lookup Failure',
  props<{ error: any }>(),
);

export const loadUserPropertiesRequest = createAction(
  '[Properties Expire Date] Load User Properties Request',
  props<{ userId: number }>(),
);

export const loadUserPropertiesSuccess = createAction(
  '[Properties Expire Date] Load User Properties Success',
  props<{
    items: PropertiesExpireDate[];
  }>(),
);

export const loadUserPropertiesFailure = createAction(
  '[Properties Expire Date] Load User Properties Failure',
  props<{ error: any }>(),
);

export const updateRequest = createAction(
  '[Properties Expire Date] Update Request',
  props<{
    properties: number;
    expiration_date: string;
  }>(),
);

export const updateSuccess = createAction(
  '[Properties Expire Date] Update Success',
);

export const updateFailure = createAction(
  '[Properties Expire Date] Update Failure',
  props<{ error: any }>(),
);

export const updateDetailsRequest = createAction(
  '[Properties Expire Date] Update Details Request',
  props<UpdatePropertiesDetailsRequest & { hooks: Hooks }>(),
);

export const updateDetailsSuccess = createAction(
  '[Properties Expire Date] Update Details Success',
);

export const updateDetailsFailure = createAction(
  '[Properties Expire Date] Update Details Failure',
  props<{ error: any }>(),
);

export const updateModulesDetailsRequest = createAction(
  '[Properties Expire Date] Update Modules Details Request',
  props<UpdatePropertiesModulesDetailsRequest & { hooks: Hooks }>(),
);

export const updateModulesDetailsSuccess = createAction(
  '[Properties Expire Date] Update Modules Details Success',
);

export const updateModulesDetailsFailure = createAction(
  '[Properties Expire Date] Update Modules Details Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[Properties Expire Date] Reset State');
