import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { RoleUser, UserNote } from '../../models';

export const featureAdapter: EntityAdapter<UserNote> =
  createEntityAdapter<UserNote>({
    selectId: (model) => model.id,
  });

export interface State extends EntityState<UserNote> {
  user: RoleUser;
  isLoading?: boolean;
  error?: any;
}

export const initialState: State = featureAdapter.getInitialState({
  user: null,
  isLoading: false,
  error: null,
});
