import { createAction, props } from '@ngrx/store';

import { NoteType, RoleUser, UserNote } from '../../models';

export const loadRequest = createAction(
  '[User Notes] Load Notes Request',
  props<{ userId: number; noteType: NoteType }>(),
);
export const loadSuccess = createAction(
  '[User Notes] Load Notes Success',
  props<{ notes: UserNote[] }>(),
);
export const loadFailure = createAction(
  '[User Notes] Load Notes Failure',
  props<{ error: any }>(),
);

export const createOrUpdateOrDeleteRequest = createAction(
  '[User Notes] Create or Update or Delete Request',
  props<{
    notes: UserNote[];
    onSuccess?: () => void;
    onFailure?: () => void;
  }>(),
);
export const createOrUpdateSuccess = createAction(
  '[User Notes] Create or Update Success',
  props<{ notes: UserNote[] }>(),
);
export const createOrUpdateFailure = createAction(
  '[User Notes] Create or Update Failure',
  props<{ error: any }>(),
);

export const deleteRequest = createAction(
  '[User Notes] Delete Request',
  props<{ noteId: number; userId: number; onSuccess?: () => void }>(),
);
export const deleteSuccess = createAction(
  '[User Notes] Delete Success',
  props<{ noteIds: number[] }>(),
);
export const deleteFailure = createAction(
  '[User Notes] Delete Failure',
  props<{ error: any }>(),
);

export const userDetailsRequest = createAction(
  '[User Notes] User Details Request',
  props<{ propertyId: number; userId: number; onSuccess?: () => void }>(),
);
export const userDetailsSuccess = createAction(
  '[User Notes] User Details Success',
  props<{ user: RoleUser }>(),
);
export const userDetailsFailure = createAction(
  '[User Notes] User Details Failure',
  props<{ error: any }>(),
);

export const createOrUpdateOrDeleteReload = createAction(
  '[User Notes] Create or Update or Delete Reload',
);
export const createOrUpdateOrDeleteFailure = createAction(
  '[User Notes] Create or Update or Delete Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[User Notes] Reset State');
