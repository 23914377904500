import { Action, createReducer, on } from '@ngrx/store';

import {
  WelcomeAutomaticEmailsLog,
  WelcomeAutomaticEmailsLogDetails,
} from '../../models';

import * as fromActions from './actions';
import * as fromState from './state';

export const reducer = createReducer(
  fromState.initialState,
  on(fromActions.loadLogsRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.loadLogsSuccess, (state, { logs }) =>
    fromState.featureAdapter.setAll(getLogsArray(logs), {
      ...state,
      isLoading: false,
      error: null,
    }),
  ),
  on(fromActions.loadLogsFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(fromActions.resendRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.resendSuccess, (state) => ({
    ...state,
    isLoading: false,
    error: null,
  })),
  on(fromActions.resendFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(fromActions.resetState, () => fromState.initialState),
);

export function welcomeAutomaticEmailsReducer(
  state: fromState.State | undefined,
  action: Action,
) {
  return reducer(state, action);
}

function getLogsArray(
  response: WelcomeAutomaticEmailsLog,
): WelcomeAutomaticEmailsLogDetails[] {
  return Object.keys(response).reduce((logs, date) => {
    const logsDetails = Object.keys(response[date]).reduce(
      (details, operationCode) => {
        return [...details, ...response[date][operationCode]];
      },
      [],
    );
    return [...logs, ...logsDetails];
  }, []);
}
