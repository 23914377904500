import { createAction, props } from '@ngrx/store';

import {
  ResendWelcomeAutomaticEmailsRequest,
  WelcomeAutomaticEmailsLog,
  WelcomeAutomaticEmailsLogDetails,
} from '../../models';

export const loadLogsRequest = createAction(
  '[Welcome Automatic Emails] Load Request Logs',
  props<{ propertyId: number }>(),
);

export const loadLogsSuccess = createAction(
  '[Welcome Automatic Emails] Load Success Logs',
  props<{ logs: WelcomeAutomaticEmailsLog }>(),
);

export const loadLogsFailure = createAction(
  '[Welcome Automatic Emails] Load Failure Logs',
  props<{ error: any }>(),
);

export const resendRequest = createAction(
  '[Welcome Automatic Emails] Resend Request Logs',
  props<{
    request: ResendWelcomeAutomaticEmailsRequest;
    onSuccess?: () => void;
    onFailure?: () => void;
  }>(),
);

export const resendSuccess = createAction(
  '[Welcome Automatic Emails] Resend Success Logs',
);

export const resendFailure = createAction(
  '[Welcome Automatic Emails] Resend Failure Logs',
  props<{ error: any }>(),
);

export const resetState = createAction(
  '[Welcome Automatic Emails Logs] Reset State',
);
