import { Injectable } from '@angular/core';
import { IResponseSuccess } from '@app/core/models/response-sucess.model';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { NotificationService } from '@app/ui/services/notification.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { effectHooks } from '../../helpers';
import { WelcomeAutomaticEmailsService } from '../../services';

import * as fromActions from './actions';

@Injectable()
export class WelcomeAutomaticEmailsStoreEffects {
  constructor(
    private dataService: WelcomeAutomaticEmailsService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
    private notifications: NotificationService,
    private translate: TranslateService,
  ) {}

  loadLogs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadLogsRequest),
      switchMap(({ propertyId }) =>
        this.dataService.loadLogs(propertyId).pipe(
          map((response: IResponseSuccess) =>
            fromActions.loadLogsSuccess({
              logs: response?.data,
            }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadLogsFailure(error));
          }),
        ),
      ),
    ),
  );

  resend$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.resendRequest),
      switchMap(({ request, onSuccess, onFailure }) =>
        this.dataService.resend(request).pipe(
          effectHooks({ onSuccess, onFailure }),
          map(() => {
            this.notifications.push({
              title: this.translate.instant('done'),
              content: this.translate.instant(
                'notifications.send_emails_success',
              ),
              type: 'success',
            });
            return fromActions.resendSuccess();
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.resendFailure(error));
          }),
        ),
      ),
    ),
  );
}
