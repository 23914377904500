import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DeleteCustomersFromEmailDateService {
  constructor(private http: HttpClient) {}

  delete(emails: string[]) {
    return this.http.post('admin/customers/gdpr/delete', {
      emails,
    });
  }
}
