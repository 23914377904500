import { Injectable } from '@angular/core';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { DeleteCustomersFromEmailDateService } from '../../services/delete-customers-from-email.service';
import { NotificationService } from '../../ui/services/notification.service';

import * as fromActions from './actions';

@Injectable()
export class DeleteCustomersFromEmailEffects {
  constructor(
    private dataService: DeleteCustomersFromEmailDateService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
    private notifications: NotificationService,
  ) {}

  deleteCustomersFromEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteCustomersFromEmailRequest),
      switchMap(({ emails }) =>
        this.dataService.delete(emails).pipe(
          map(() => {
            this.notifications.done('customers_deleted');
            return fromActions.deleteCustomersFromEmailSuccess();
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.deleteCustomersFromEmailFailure(error));
          }),
        ),
      ),
    ),
  );
}
