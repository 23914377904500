import { createFeatureSelector, createSelector } from '@ngrx/store';

import { UserNote } from '../../models';
import { NoteType } from '../../models/types';

import { featureAdapter, State } from './state';

export const selectState = createFeatureSelector<State>('userNotes');

export const selectAllItems =
  featureAdapter.getSelectors(selectState).selectAll;

export const selectUserNotesWithType = (type: NoteType) =>
  createSelector(selectAllItems, (notes: UserNote[]) =>
    notes.filter((note) => note.type === type),
  );

export const selectUser = createSelector(selectState, (state) => state.user);

export const selectError = createSelector(selectState, (state) => state.error);

export const selectIsLoading = createSelector(
  selectState,
  (state) => state.isLoading,
);
