import { Injectable } from '@angular/core';
import { IResponseSuccess } from '@app/core/models/response-sucess.model';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TypedAction } from '@ngrx/store/src/models';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';

import { effectHooks } from '../../helpers';
import { NoteActionType, RoleUser, UserNote } from '../../models';
import { RoleUserDetailsService, UserNotesService } from '../../services';
import { NotificationService } from '../../ui/services/notification.service';

import * as fromActions from './actions';

@Injectable()
export class UserNotesStoreEffects {
  constructor(
    private dataService: UserNotesService,
    private userDetailsService: RoleUserDetailsService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
    private notifications: NotificationService,
    private translate: TranslateService,
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadRequest),
      switchMap(({ userId, noteType }) =>
        this.dataService.load(userId, noteType).pipe(
          map((response: IResponseSuccess<UserNote[]>) =>
            fromActions.loadSuccess({
              notes: response.data,
            }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadFailure(error));
          }),
        ),
      ),
    ),
  );

  createOrUpdateOrDelete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.createOrUpdateOrDeleteRequest),
      switchMap(({ notes, onSuccess, onFailure }) =>
        this.dataService.createOrUpdateOrDelete(notes).pipe(
          effectHooks({ onSuccess, onFailure }),
          mergeMap((responses: Array<IResponseSuccess<UserNote>>) => {
            this.createNotification(responses);

            let actions: TypedAction<NoteActionType>[] = [
              fromActions.createOrUpdateOrDeleteReload(),
            ];

            const responsesSuccess = responses.filter(
              (response) => response.meta?.status === 'success',
            );

            const responseToCreateOrUpdate = responsesSuccess
              .filter((response) => !response?.data?.toDelete)
              .map(({ data }) => data[0]);

            const responseToDelete = responsesSuccess
              .filter((response) => response?.data?.toDelete)
              .map(({ data }) => data.id);

            if (responseToCreateOrUpdate?.length) {
              actions = [
                ...actions,
                fromActions.createOrUpdateSuccess({
                  notes: responseToCreateOrUpdate,
                }),
              ];
            }

            if (responseToDelete?.length) {
              actions = [
                ...actions,
                fromActions.deleteSuccess({
                  noteIds: responseToDelete,
                }),
              ];
            }

            return actions;
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.createOrUpdateOrDeleteFailure(error));
          }),
        ),
      ),
    ),
  );

  loadUserDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.userDetailsRequest),
      switchMap(({ userId, propertyId }) =>
        this.userDetailsService.load(propertyId, userId).pipe(
          map((response: IResponseSuccess<RoleUser>) =>
            fromActions.userDetailsSuccess({
              user: response.data,
            }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.userDetailsFailure(error));
          }),
        ),
      ),
    ),
  );

  createNotification(responses) {
    if (responses.some((response) => response?.meta?.status !== 'success')) {
      this.notifications.push({
        title: this.translate.instant('warning'),
        content: this.translate.instant('notifications.update_warning'),
        type: 'warning',
      });
      return;
    }
    this.notifications.push({
      title: this.translate.instant('done'),
      content: this.translate.instant('notifications.update_success', {
        param: this.translate.instant('notes'),
      }),
      type: 'success',
    });
  }
}
