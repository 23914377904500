import { createAction, props } from '@ngrx/store';

import { CloneableOption } from '../../models';

export const loadRequest = createAction('[Cloneable Options] Load Request');

export const loadSuccess = createAction(
  '[Cloneable Options] Load Success',
  props<{ cloneableOptions: CloneableOption[] }>(),
);

export const loadFailure = createAction(
  '[Cloneable Options] Load Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[Cloneable Options] Reset State');
