import { Injectable } from '@angular/core';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { AdminStatsService } from '../../services';

import * as fromActions from './actions';

@Injectable()
export class AdminStatsStoreEffects {
  constructor(
    private dataService: AdminStatsService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadRequest),
      switchMap(({ statsType, searchParams, onSuccess }) =>
        this.dataService.load(statsType, searchParams).pipe(
          map(({ data }: any) => {
            if (onSuccess) {
              onSuccess(data);
            }
            return fromActions.loadSuccess({ data, statsType });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadFailure(error));
          }),
        ),
      ),
    ),
  );
}
