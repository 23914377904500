import { createFeatureSelector, createSelector } from '@ngrx/store';

import { AdminStats } from '../../models';

import { State } from './state';

export const getError = (state: State) => state.error;

export const getIsLoading = (state: State) => state.isLoading;

export const getData = (state: State) => state.data;

export const selectState = createFeatureSelector<State>('adminStats');

export const selectData = createSelector(selectState, getData);

export const selectByType = (statsType: string) =>
  createSelector(
    selectData,
    (items: { [statsType: string]: AdminStats }) => items?.[statsType],
  );

export const selectError = createSelector(selectState, getError);

export const selectIsLoading = createSelector(selectState, getIsLoading);
