import { Action, createReducer, on } from '@ngrx/store';

import * as fromActions from './actions';
import * as fromState from './state';

export const reducer = createReducer(
  fromState.initialState,

  on(fromActions.loadRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.loadSuccess, (state, { notes }) =>
    fromState.featureAdapter.setAll(notes, {
      ...state,
      isLoading: false,
      error: null,
    }),
  ),
  on(fromActions.loadFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),

  on(fromActions.createOrUpdateOrDeleteRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),

  on(fromActions.createOrUpdateSuccess, (state, { notes }) =>
    fromState.featureAdapter.upsertMany(notes, {
      ...state,
      isLoading: false,
      error: null,
    }),
  ),
  on(fromActions.createOrUpdateFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),

  on(fromActions.createOrUpdateOrDeleteReload, (state) => ({
    ...state,
    isLoading: false,
    error: null,
  })),

  on(fromActions.createOrUpdateOrDeleteFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),

  on(fromActions.deleteRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.deleteSuccess, (state, { noteIds }) =>
    fromState.featureAdapter.removeMany(noteIds, {
      ...state,
      isLoading: false,
      error: null,
    }),
  ),
  on(fromActions.deleteFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),

  on(fromActions.userDetailsRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.userDetailsSuccess, (state, { user }) => ({
    ...state,
    user,
    isLoading: false,
    error: null,
  })),
  on(fromActions.userDetailsFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(fromActions.resetState, () => fromState.initialState),
);

export function userNotesReducer(
  state: fromState.State | undefined,
  action: Action,
) {
  return reducer(state, action);
}
